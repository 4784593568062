<template>
  <div>
    <!-- Filters -->
    <shared-user-management-company-admin-filters
      :name-filter.sync="filter.name"
      :email-filter.sync="filter.email"
      :gender-filter.sync="filter.gender"
      :state-type-filter.sync="filter.state_type"
      :gender-options="genderOptions"
      :state-type-options="stateTypeOptions"
      @click="loadData"
    />

    <!-- Table Top -->
    <div class="m-2">
      <b-row>
        <b-col
          cols="12"
          md="12"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-button
              variant="primary"
              @click="registerAdminModal"
            >
              {{ $t('register') }}
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>

    <!-- Table Container Card -->
    <b-overlay
      :show="loading"
      opacity="0.10"
      rounded="sm"
    >
      <b-card
        no-body
        class="mb-0"
      >
        <b-table
          responsive
          :items="items"
          :fields="fields"
          class="mb-0"
        >

          <!-- Column: id -->
          <template #cell(id)="data">
            <span
              class="align-middle ml-50"
              v-text="data.item.count"
            />
          </template>

          <!-- Column: name -->
          <template #cell(name)="data">
            <div
              class="text-nowrap"
            >
              <b-avatar
                :src="data.item.avatar"
                class="mr-1"
              />
              <span
                class="text-nowrap"
                v-text="data.item.name"
              />
            </div>
          </template>

          <!-- Column: position -->
          <template #cell(position)="data">
            <span
              v-if="data.item.position !== null"
              class="align-middle ml-50"
              v-text="data.item.position.name_ko"
            />
          </template>

          <!-- Column: job -->
          <template #cell(job)="data">
            <span
              v-if="data.item.job !== null"
              class="align-middle ml-50"
              v-text="data.item.job.name_ko"
            />
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item
                @click="editAdminModal(data)"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">{{ $t('edit') }}</span>
              </b-dropdown-item>
              <b-dropdown-item
                @click="setupLevel(data)"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">{{ $t('setup_level') }}</span>
              </b-dropdown-item>
              <b-dropdown-item
                @click="setupTime(data)"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">{{ $t('setup_time') }}</span>
              </b-dropdown-item>
              <b-dropdown-item
                @click="setupCompany(data)"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">{{ $t('setup_company') }}</span>
              </b-dropdown-item>
              <b-dropdown-item
                @click="deleteCompany(data)"
              >
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">{{ $t('delete') }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>

        </b-table>
        <template slot="footer">
          <b-pagination
            v-model="filter.page"
            :per-page="perPage"
            hide-goto-end-buttons
            :total-rows="totalPage"
            align="center"
            @change="loadData"
          />
        </template>
      </b-card>
    </b-overlay>

    <b-modal
      ref="register-admin-modal"
      cancel-variant="outline-secondary"
      ok-title="Register"
      cancel-title="Close"
      centered
      no-close-on-backdrop
      size="lg"
      title="Register Form"
      @ok="registerHandleOk"
      @close="resetFormData"
      @cancel="resetFormData"
    >
      <validation-observer ref="registerSimpleRules">
        <b-form @submit.stop.prevent="registerHandleSubmit">
          <b-row>
            <b-col
              cols="12"
              md="12  "
              class="mb-md-0 mb-2"
            >
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('name')"
                  rules="required"
                >
                  <label>{{ $t('name') }}</label>
                  <b-form-input
                    v-model="formData.name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
              class="mb-md-0 mb-2"
            >
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('username')"
                  rules="required"
                >
                  <label>{{ $t('username') }}</label>
                  <b-form-input
                    v-model="formData.username"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
              class="mb-md-0 mb-2"
            >
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('password')"
                  rules="required"
                >
                  <label>{{ $t('password') }}</label>
                  <b-form-input
                    v-model="formData.password"
                    type="password"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="12"
              class="mb-md-0 mb-2"
            >
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('email')"
                  rules="required|email"
                >
                  <label>{{ $t('email') }}</label>
                  <b-form-input
                    v-model="formData.email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="4"
              class="mb-md-0 mb-2"
            >
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('gender')"
                >
                  <label>{{ $t('gender') }}</label>
                  <v-select
                    v-model="formData.gender"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="genderOptions"
                    class="w-100"
                    :reduce="val => val.value"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="4"
              class="mb-md-0 mb-2"
            >
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('position')"
                >
                  <label>{{ $t('position') }}</label>
                  <v-select
                    v-model="formData.position_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="positionOptions"
                    class="w-100"
                    :reduce="val => val.value"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="4"
              class="mb-md-0 mb-2"
            >
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('job')"
                >
                  <label>{{ $t('job') }}</label>
                  <v-select
                    v-model="formData.job_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="jobOptions"
                    class="w-100"
                    :reduce="val => val.value"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="12"
              class="mb-md-0 mb-2"
            >
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('memo')"
                >
                  <label>{{ $t('memo') }}</label>
                  <b-form-textarea
                    v-model="formData.memo"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
              class="mb-md-0 mb-2"
            >
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('hire_date')"
                >
                  <label>{{ $t('hire_date') }}</label>
                  <b-form-datepicker
                    v-model="formData.hire_date"
                    autocomplete="off"
                    class="mb-1"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
              class="mb-md-0 mb-2"
            >
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('dismissal_date')"
                >
                  <label>{{ $t('dismissal_date') }}</label>
                  <b-form-datepicker
                    v-model="formData.dismissal_date"
                    autocomplete="off"
                    class="mb-1"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="12"
              class="mb-md-0 mb-2"
            >
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('academic')"
                >
                  <label>{{ $t('academic') }}</label>
                  <b-form-input
                    v-model="formData.academic"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="12"
              class="mb-md-0 mb-2"
            >
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('major')"
                >
                  <label>{{ $t('major') }}</label>
                  <b-form-input
                    v-model="formData.major"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="12"
              class="mb-md-0 mb-2"
            >
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('career')"
                >
                  <label>{{ $t('career') }}</label>
                  <b-form-input
                    v-model="formData.career"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="12"
              class="mb-md-0 mb-2"
            >
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('license')"
                >
                  <label>{{ $t('license') }}</label>
                  <b-form-input
                    v-model="formData.license"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="12"
              class="mb-md-0 mb-2"
            >
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('birth')"
                >
                  <label>{{ $t('birth') }}</label>
                  <b-form-datepicker
                    v-model="formData.birth"
                    autocomplete="off"
                    class="mb-1"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="12"
              class="mb-md-0 mb-2"
            >
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('country')"
                >
                  <label>{{ $t('country') }}</label>
                  <b-form-input
                    v-model="formData.country"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="12"
              class="mb-md-0 mb-2"
            >
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('image')"
                >
                  <label>{{ $t('image') }}</label>
                  <b-form-file
                    v-model="formData.image"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="12"
              class="mb-md-0 mb-2"
            >
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('cell_phone')"
                >
                  <label>{{ $t('cell_phone') }}</label>
                  <b-form-input
                    v-model="formData.cell_phone"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="12"
              class="mb-md-0 mb-2"
            >
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('state_type')"
                >
                  <label>{{ $t('state_type') }}</label>
                  <v-select
                    v-model="formData.state_type"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="stateTypeOptions"
                    class="w-100"
                    :reduce="val => val.value"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>

    <b-modal
      ref="edit-admin-modal"
      cancel-variant="outline-secondary"
      ok-title="Update"
      cancel-title="Close"
      centered
      no-close-on-backdrop
      size="lg"
      title="Edit Form"
      @ok="editHandleOk"
      @close="resetFormData"
      @cancel="resetFormData"
    >
      <validation-observer ref="editSimpleRules">
        <b-form @submit.stop.prevent="editHandleSubmit">
          <b-row>
            <b-col
              cols="12"
              md="12  "
              class="mb-md-0 mb-2"
            >
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('name')"
                  rules="required"
                >
                  <label>{{ $t('name') }}</label>
                  <b-form-input
                    v-model="formData.name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="12"
              class="mb-md-0 mb-2"
            >
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('username')"
                  rules="required"
                >
                  <label>{{ $t('username') }}</label>
                  <b-form-input
                    v-model="formData.username"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="12"
              class="mb-md-0 mb-2"
            >
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('email')"
                  rules="required|email"
                >
                  <label>{{ $t('email') }}</label>
                  <b-form-input
                    v-model="formData.email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="4"
              class="mb-md-0 mb-2"
            >
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('gender')"
                >
                  <label>{{ $t('gender') }}</label>
                  <v-select
                    v-model="formData.gender"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="genderOptions"
                    class="w-100"
                    :reduce="val => val.value"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="4"
              class="mb-md-0 mb-2"
            >
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('position')"
                >
                  <label>{{ $t('position') }}</label>
                  <v-select
                    v-model="formData.position_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="positionOptions"
                    class="w-100"
                    :reduce="val => val.value"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="4"
              class="mb-md-0 mb-2"
            >
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('job')"
                >
                  <label>{{ $t('job') }}</label>
                  <v-select
                    v-model="formData.job_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="jobOptions"
                    class="w-100"
                    :reduce="val => val.value"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="12"
              class="mb-md-0 mb-2"
            >
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('memo')"
                >
                  <label>{{ $t('memo') }}</label>
                  <b-form-textarea
                    v-model="formData.memo"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
              class="mb-md-0 mb-2"
            >
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('hire_date')"
                >
                  <label>{{ $t('hire_date') }}</label>
                  <b-form-datepicker
                    v-model="formData.hire_date"
                    autocomplete="off"
                    class="mb-1"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
              class="mb-md-0 mb-2"
            >
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('dismissal_date')"
                >
                  <label>{{ $t('dismissal_date') }}</label>
                  <b-form-datepicker
                    v-model="formData.dismissal_date"
                    autocomplete="off"
                    class="mb-1"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="12"
              class="mb-md-0 mb-2"
            >
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('academic')"
                >
                  <label>{{ $t('academic') }}</label>
                  <b-form-input
                    v-model="formData.academic"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="12"
              class="mb-md-0 mb-2"
            >
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('major')"
                >
                  <label>{{ $t('major') }}</label>
                  <b-form-input
                    v-model="formData.major"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="12"
              class="mb-md-0 mb-2"
            >
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('career')"
                >
                  <label>{{ $t('career') }}</label>
                  <b-form-input
                    v-model="formData.career"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="12"
              class="mb-md-0 mb-2"
            >
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('license')"
                >
                  <label>{{ $t('license') }}</label>
                  <b-form-input
                    v-model="formData.license"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="12"
              class="mb-md-0 mb-2"
            >
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('birth')"
                >
                  <label>{{ $t('birth') }}</label>
                  <b-form-datepicker
                    v-model="formData.birth"
                    autocomplete="off"
                    class="mb-1"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="12"
              class="mb-md-0 mb-2"
            >
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('country')"
                >
                  <label>{{ $t('country') }}</label>
                  <b-form-input
                    v-model="formData.country"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="12"
              class="mb-md-0 mb-2"
            >
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('image')"
                >
                  <label>{{ $t('image') }}</label>
                  <b-form-file
                    v-model="formData.image"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="12"
              class="mb-md-0 mb-2"
            >
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('cell_phone')"
                >
                  <label>{{ $t('cell_phone') }}</label>
                  <b-form-input
                    v-model="formData.cell_phone"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="12"
              class="mb-md-0 mb-2"
            >
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('state_type')"
                >
                  <label>{{ $t('state_type') }}</label>
                  <v-select
                    v-model="formData.state_type"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="stateTypeOptions"
                    class="w-100"
                    :reduce="val => val.value"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>

    <b-modal
      ref="setup-admin-levels-modal"
      cancel-variant="outline-secondary"
      ok-title="Update"
      cancel-title="Close"
      centered
      no-close-on-backdrop
      size="xl"
      :title="$t('setup_level')"
      @ok="setupLevelHandleOk"
      @close="resetFormData"
      @cancel="resetFormData"
    >
      <validation-observer ref="setupLevelSimpleRules">
        <b-form @submit.stop.prevent="setupLevelsHandleSubmit">
          <b-row>
            <b-col
              cols="12"
              md="12"
              class="mb-md-0 mb-2"
            >
              <b-form-group>
                <label>{{ $t('levels') }}</label>
                <b-form-checkbox-group
                  v-model="formData.selected_levels"
                  style="column-count: 5;"
                  class="levels-checkbox-container"
                  stacked
                  :options="levelOptions"
                  value-field="value"
                  text-field="label"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>

    <b-modal
      ref="setup-admin-times-modal"
      cancel-variant="outline-secondary"
      ok-title="Update"
      cancel-title="Close"
      centered
      no-close-on-backdrop
      size="lg"
      :title="$t('setup_time')"
      @ok="setupTimeHandleOk"
      @close="resetFormData"
      @cancel="resetFormData"
    >
      <validation-observer ref="setupTimeSimpleRules">
        <b-form @submit.stop.prevent="setupTimesHandleSubmit">
          <b-row>
            <b-col
              cols="12"
              md="12"
              class="mb-md-0 mb-2"
            >
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('talk_center')"
                >
                  <label>{{ $t('talk_center') }}</label>
                  <v-select
                    v-model="formData.talk_center"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="talkCenterOptions"
                    class="w-100"
                    :reduce="val => val.id"
                    label="name"
                    @input="fetchTalkOperationTimes"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('weekday')"
                >
                  <label>{{ $t('weekday') }}</label>
                  <v-select
                    v-model="formData.weekday"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="weekDayOptions"
                    class="w-100"
                    :reduce="val => val.value"
                    @input="fetchTalkOperationTimes"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group>
                <b-form-checkbox-group
                  v-model="formData.selected_times"
                  class="w-100 times-checkbox-container"
                  stacked
                  :options="talkOperationTimeOptions"
                  value-field="id"
                  text-field="name"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>

    <b-modal
      ref="setup-admin-companies-modal"
      cancel-variant="outline-secondary"
      ok-title="Update"
      cancel-title="Close"
      centered
      no-close-on-backdrop
      size="xl"
      :title="$t('setup_company')"
      @ok="setupCompanyHandleOk"
      @close="resetFormData"
      @cancel="resetFormData"
    >
      <validation-observer ref="setupCompanySimpleRules">
        <b-form @submit.stop.prevent="setupCompaniesHandleSubmit">
          <b-row>
            <b-col
              cols="12"
              md="12"
              class="mb-md-0 mb-2"
            >
              <b-form-radio-group
                v-model="formData.selected_companies"
                style="column-count: 5;"
                class="companies-checkbox-container"
                :options="companyOptions"
                value-field="value"
                text-field="label"
              />
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>

  </div>
</template>

<script>

import {
  required,
} from '@validations'

import {
  BRow,
  BCol,
  BCard,
  BTable,
  BButton,
  BDropdown,
  BDropdownItem,
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormFile,
  BFormTextarea,
  BFormDatepicker,
  BFormCheckboxGroup,
  BFormRadioGroup,
  BPagination,
  BOverlay,
  BAvatar,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Admin from '@/models/Admin'
import SharedUserManagementCompanyAdminFilters from '../../Shared/UserManagement/CompanyAdmin/Filter.vue'

export default {
  name: 'UserManagementAdmin',
  components: {
    ValidationProvider,
    ValidationObserver,
    SharedUserManagementCompanyAdminFilters,
    BRow,
    BCol,
    BCard,
    BTable,
    BButton,
    BDropdown,
    BDropdownItem,
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormTextarea,
    BFormDatepicker,
    vSelect,
    BFormCheckboxGroup,
    BFormRadioGroup,
    BPagination,
    BOverlay,
    BAvatar,
  },
  data() {
    return {
      required,
      fields: [
        'id',
        'name',
        'username',
        'email',
        'gender',
        'position',
        'job',
        'memo',
        'hire_date',
        'dismissal_date',
        'academic',
        'major',
        'career',
        'license',
        'birth',
        'country',
        'cell_phone',
        'state_type',
        'actions',
      ],
      items: [],
      loading: true,
      perPage: 10,
      totalPage: 0,
      filter: {
        name: typeof this.$route.query.name !== 'undefined' ? this.$route.query.name : '',
        email: typeof this.$route.query.email !== 'undefined' ? this.$route.query.email : '',
        gender: typeof this.$route.query.gender !== 'undefined' ? this.$route.query.gender : '',
        state_type: typeof this.$route.query.state_type !== 'undefined' ? this.$route.query.state_type : '',
        role: 'company_admin',
      },
      formData: {
        id: '',
        name: '',
        username: '',
        password: '',
        email: '',
        gender: '',
        position_id: '',
        job_id: '',
        memo: '',
        hire_date: '',
        dismissal_date: '',
        academic: '',
        major: '',
        career: '',
        license: '',
        birth: '',
        country: '',
        image: null,
        cell_phone: '',
        state_type: '',
        selected_levels: [],
        selected_times: [],
        selected_companies: '',
        talk_center: '',
        weekday: '',
        role: 'company_admin',
      },
      genderOptions: [
        { label: 'Male', value: 'male' },
        { label: 'Female', value: 'female' },
      ],
      stateTypeOptions: [
        { label: 'Tenure', value: 'tenure' },
        { label: 'Retirement', value: 'retirement' },
      ],
      weekDayOptions: [
        { label: 'Monday', value: 'monday' },
        { label: 'Tuesday', value: 'tuesday' },
        { label: 'Wednesday', value: 'wednesday' },
        { label: 'Thursday', value: 'thursday' },
        { label: 'Friday', value: 'friday' },
      ],
      jobOptions: [],
      positionOptions: [],
      levelOptions: [],
      talkCenterOptions: [],
      talkOperationTimeOptions: [],
      companyOptions: [],
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    async loadData(page = 1) {
      this.loading = true

      const searchParams = {}
      searchParams.page = page
      Object.keys(this.filter).forEach(e => {
        if (this.filter[e] !== null && this.filter[e].length > 0) {
          searchParams[e] = this.filter[e]
        }
      })
      this.$router.replace({ query: searchParams })

      const response = await Admin.list(searchParams)
      this.items = response.data.data
      this.jobOptions = response.data.meta.options.jobs
      this.positionOptions = response.data.meta.options.positions
      this.levelOptions = response.data.meta.options.levels
      this.talkCenterOptions = response.data.meta.options.talkCenters
      this.companyOptions = response.data.meta.options.companies
      this.totalPage = response.data.meta.pagination.total
      this.perPage = response.data.meta.pagination.per_page
      this.filter.page = response.data.meta.pagination.current_page
      this.loading = false
    },
    resetFormData() {
      this.formData = {
        id: '',
        name: '',
        username: '',
        password: '',
        email: '',
        gender: '',
        position_id: '',
        job_id: '',
        memo: '',
        hire_date: '',
        dismissal_date: '',
        academic: '',
        major: '',
        career: '',
        license: '',
        birth: '',
        country: '',
        image: '',
        cell_phone: '',
        state_type: '',
        selected_levels: [],
        selected_times: [],
        talk_center: '',
        weekday: '',
        role: 'company_admin',
      }
      this.talkOperationTimeOptions = []
    },
    async deleteAdmin(data) {
      const doWeDelete = await this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })

      if (doWeDelete.value) {
        try {
          const response = await Admin.destroy(data.item.id)
          this.$swal({
            title: 'Success!',
            text: response.data,
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
          this.loadData()
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: e.message,
            },
          })
        }
      }
    },
    registerAdminModal() {
      this.$refs['register-admin-modal'].show()
    },
    registerHandleOk(e) {
      // Prevent modal from closing
      e.preventDefault()
      // Trigger submit handler
      this.registerHandleSubmit()
    },
    async registerHandleSubmit() {
      const validator = await this.$refs.registerSimpleRules.validate()

      if (validator) {
        try {
          const response = await Admin.store(this.formData)

          this.$swal({
            title: 'Success!',
            text: response.data,
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
          await this.loadData()
          this.$refs['register-admin-modal'].hide()
          this.resetFormData()
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: e.message,
            },
          })
        }
      }
    },
    async editAdminModal(data) {
      this.resetFormData()

      try {
        const response = await Admin.view(data.item.id)
        this.formData = response.data
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Error',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: e.message,
          },
        })
      }

      this.$refs['edit-admin-modal'].show()
    },
    editHandleOk(e) {
      // Prevent modal from closing
      e.preventDefault()
      // Trigger submit handler
      this.editHandleSubmit()
    },
    async editHandleSubmit() {
      const validator = await this.$refs.editSimpleRules.validate()

      if (validator) {
        try {
          const response = await Admin.update(this.formData)
          this.$swal({
            title: 'Success!',
            text: response.data,
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
          this.loadData()
          this.$refs['edit-admin-modal'].hide()
          this.resetFormData()
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: e.message,
            },
          })
        }
      }
    },
    async setupLevel(data) {
      this.resetFormData()

      try {
        const response = await Admin.view(data.item.id)
        this.formData = response.data
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Error',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: e.message,
          },
        })
      }

      this.$refs['setup-admin-levels-modal'].show()
    },
    setupLevelHandleOk(e) {
      // Prevent modal from closing
      e.preventDefault()
      // Trigger submit handler
      this.setupLevelsHandleSubmit()
    },
    async setupLevelsHandleSubmit() {
      const validator = await this.$refs.setupLevelSimpleRules.validate()

      if (validator) {
        try {
          const response = await Admin.updateSetupLevel(this.formData)
          this.$swal({
            title: 'Success!',
            text: response.data,
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
          this.loadData()
          this.$refs['setup-admin-levels-modal'].hide()
          this.resetFormData()
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: e.message,
            },
          })
        }
      }
    },
    async fetchTalkOperationTimes() {
      if (typeof this.formData.weekday !== 'undefined' && typeof this.formData.talk_center !== 'undefined') {
        const response = await Admin.fetchTalkOperationTimes(this.formData)
        this.formData.selected_times = response.data.data
        this.talkOperationTimeOptions = response.data.meta.options.talkOperationTimes
      }
    },
    async setupTime(data) {
      this.resetFormData()

      try {
        const response = await Admin.view(data.item.id)
        this.formData = response.data
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Error',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: e.message,
          },
        })
      }

      this.$refs['setup-admin-times-modal'].show()
    },
    setupTimeHandleOk(e) {
      // Prevent modal from closing
      e.preventDefault()
      // Trigger submit handler
      this.setupTimesHandleSubmit()
    },
    async setupTimesHandleSubmit() {
      const validator = await this.$refs.setupTimeSimpleRules.validate()

      if (validator) {
        try {
          const response = await Admin.updateSetupTime(this.formData)
          this.$swal({
            title: 'Success!',
            text: response.data,
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
          this.loadData()
          this.$refs['setup-admin-times-modal'].hide()
          this.resetFormData()
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: e.message,
            },
          })
        }
      }
    },
    async setupCompany(data) {
      this.resetFormData()

      try {
        const response = await Admin.view(data.item.id)
        this.formData = response.data
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Error',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: e.message,
          },
        })
      }

      this.$refs['setup-admin-companies-modal'].show()
    },
    setupCompanyHandleOk(e) {
      // Prevent modal from closing
      e.preventDefault()
      // Trigger submit handler
      this.setupCompaniesHandleSubmit()
    },
    async setupCompaniesHandleSubmit() {
      const validator = await this.$refs.setupCompanySimpleRules.validate()

      if (validator) {
        try {
          const response = await Admin.updateSetupCompany(this.formData)
          this.$swal({
            title: 'Success!',
            text: response.data,
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
          this.loadData()
          this.$refs['setup-admin-companies-modal'].hide()
          this.resetFormData()
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: e.message,
            },
          })
        }
      }
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.levels-checkbox-container .custom-checkbox, .times-checkbox-container .custom-checkbox {
  margin-bottom: 5px;
}
</style>
